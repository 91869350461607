
















































































































































































































.banner-side-image {
}
.profile-name {
  letter-spacing: 2px;
  text-transform: uppercase; 
}
.location-marker {
  width: 16px;
}
.username-tag {
  text-transform: lowercase;
}
